(function (cjs, an) {

var p; // shortcut to reference prototypes
var lib={};var ss={};var img={};
lib.ssMetadata = [
		{name:"index_atlas_1", frames: [[653,488,322,118],[682,850,190,52],[478,796,251,52],[731,796,244,52],[0,851,184,52],[478,850,202,52],[0,784,281,65],[0,488,651,146],[0,0,1002,242],[283,796,193,78],[402,716,515,78],[402,636,521,78],[0,636,400,146],[0,244,1002,242]]},
		{name:"index_atlas_2", frames: [[0,1615,1006,356],[1008,1615,606,426],[0,0,847,712],[849,0,846,711],[849,713,841,699],[0,714,840,698],[0,1414,1920,199]]},
		{name:"index_atlas_3", frames: [[714,0,798,799],[713,1021,798,800],[0,0,712,1019],[0,1021,711,1018]]},
		{name:"index_atlas_4", frames: [[0,0,1523,1065]]},
		{name:"index_atlas_5", frames: [[0,0,1920,2008]]},
		{name:"index_atlas_6", frames: [[0,0,1920,2008]]}
];


(lib.AnMovieClip = function(){
	this.actionFrames = [];
	this.ignorePause = false;
	this.gotoAndPlay = function(positionOrLabel){
		cjs.MovieClip.prototype.gotoAndPlay.call(this,positionOrLabel);
	}
	this.play = function(){
		cjs.MovieClip.prototype.play.call(this);
	}
	this.gotoAndStop = function(positionOrLabel){
		cjs.MovieClip.prototype.gotoAndStop.call(this,positionOrLabel);
	}
	this.stop = function(){
		cjs.MovieClip.prototype.stop.call(this);
	}
}).prototype = p = new cjs.MovieClip();
// symbols:



(lib.CachedBmp_16 = function() {
	this.initialize(ss["index_atlas_1"]);
	this.gotoAndStop(0);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_34 = function() {
	this.initialize(ss["index_atlas_1"]);
	this.gotoAndStop(1);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_33 = function() {
	this.initialize(ss["index_atlas_1"]);
	this.gotoAndStop(2);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_32 = function() {
	this.initialize(ss["index_atlas_1"]);
	this.gotoAndStop(3);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_31 = function() {
	this.initialize(ss["index_atlas_1"]);
	this.gotoAndStop(4);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_30 = function() {
	this.initialize(ss["index_atlas_1"]);
	this.gotoAndStop(5);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_29 = function() {
	this.initialize(ss["index_atlas_1"]);
	this.gotoAndStop(6);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_28 = function() {
	this.initialize(ss["index_atlas_2"]);
	this.gotoAndStop(0);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_27 = function() {
	this.initialize(img.CachedBmp_27);
}).prototype = p = new cjs.Bitmap();
p.nominalBounds = new cjs.Rectangle(0,0,3840,2160);


(lib.CachedBmp_8 = function() {
	this.initialize(ss["index_atlas_1"]);
	this.gotoAndStop(7);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_7 = function() {
	this.initialize(ss["index_atlas_1"]);
	this.gotoAndStop(8);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_26 = function() {
	this.initialize(ss["index_atlas_1"]);
	this.gotoAndStop(9);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_24 = function() {
	this.initialize(ss["index_atlas_1"]);
	this.gotoAndStop(10);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_22 = function() {
	this.initialize(ss["index_atlas_1"]);
	this.gotoAndStop(11);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_25 = function() {
	this.initialize(ss["index_atlas_2"]);
	this.gotoAndStop(1);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_2 = function() {
	this.initialize(ss["index_atlas_1"]);
	this.gotoAndStop(12);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_1 = function() {
	this.initialize(ss["index_atlas_1"]);
	this.gotoAndStop(13);
}).prototype = p = new cjs.Sprite();



(lib.background = function() {
	this.initialize(ss["index_atlas_6"]);
	this.gotoAndStop(0);
}).prototype = p = new cjs.Sprite();



(lib.big = function() {
	this.initialize(ss["index_atlas_4"]);
	this.gotoAndStop(0);
}).prototype = p = new cjs.Sprite();



(lib.dinosaurblue1 = function() {
	this.initialize(ss["index_atlas_3"]);
	this.gotoAndStop(0);
}).prototype = p = new cjs.Sprite();



(lib.dinosaurblueblack = function() {
	this.initialize(ss["index_atlas_3"]);
	this.gotoAndStop(1);
}).prototype = p = new cjs.Sprite();



(lib.dinosaurgreenblack = function() {
	this.initialize(ss["index_atlas_3"]);
	this.gotoAndStop(2);
}).prototype = p = new cjs.Sprite();



(lib.dinosaurgreen = function() {
	this.initialize(ss["index_atlas_3"]);
	this.gotoAndStop(3);
}).prototype = p = new cjs.Sprite();



(lib.dinosaurredblack = function() {
	this.initialize(ss["index_atlas_2"]);
	this.gotoAndStop(2);
}).prototype = p = new cjs.Sprite();



(lib.dinosaurred = function() {
	this.initialize(ss["index_atlas_2"]);
	this.gotoAndStop(3);
}).prototype = p = new cjs.Sprite();



(lib.dinosauryellowblack = function() {
	this.initialize(ss["index_atlas_2"]);
	this.gotoAndStop(4);
}).prototype = p = new cjs.Sprite();



(lib.dinosauryellow = function() {
	this.initialize(ss["index_atlas_2"]);
	this.gotoAndStop(5);
}).prototype = p = new cjs.Sprite();



(lib.gram01 = function() {
	this.initialize(ss["index_atlas_2"]);
	this.gotoAndStop(6);
}).prototype = p = new cjs.Sprite();



(lib.gram02 = function() {
	this.initialize(ss["index_atlas_5"]);
	this.gotoAndStop(0);
}).prototype = p = new cjs.Sprite();
// helper functions:

function mc_symbol_clone() {
	var clone = this._cloneProps(new this.constructor(this.mode, this.startPosition, this.loop, this.reversed));
	clone.gotoAndStop(this.currentFrame);
	clone.paused = this.paused;
	clone.framerate = this.framerate;
	return clone;
}

function getMCSymbolPrototype(symbol, nominalBounds, frameBounds) {
	var prototype = cjs.extend(symbol, cjs.MovieClip);
	prototype.clone = mc_symbol_clone;
	prototype.nominalBounds = nominalBounds;
	prototype.frameBounds = frameBounds;
	return prototype;
	}


(lib.text = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// Camada_1
	this.instance = new lib.CachedBmp_16();
	this.instance.setTransform(0,0,0.5,0.5);

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.text, new cjs.Rectangle(0,0,161,59), null);


(lib.size_160x160 = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// size
	this.shape = new cjs.Shape();
	this.shape.graphics.beginFill("rgba(255,255,255,0)").beginStroke().moveTo(-56.6,56.5).curveTo(-80,33.1,-80,0).curveTo(-80,-33.2,-56.6,-56.6).curveTo(-33.2,-80,0,-80).curveTo(33.1,-80,56.5,-56.6).curveTo(80,-33.2,80,0).curveTo(80,33.1,56.5,56.5).curveTo(33.1,80,0,80).curveTo(-33.2,80,-56.6,56.5).closePath();
	this.shape.setTransform(80,80);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.size_160x160, new cjs.Rectangle(0,0,160,160), null);


(lib.Interpolação2 = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// Camada_1
	this.shape = new cjs.Shape();
	this.shape.graphics.beginFill().beginStroke("#74ACBC").setStrokeStyle(3,1,1).moveTo(0,-25).lineTo(0,25);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(-1.5,-26.5,3,53);


(lib.Interpolação1 = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// Camada_1
	this.shape = new cjs.Shape();
	this.shape.graphics.beginFill().beginStroke("#74ACBC").setStrokeStyle(3,1,1).moveTo(0,-25).lineTo(0,25);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(-1.5,-26.5,3,53);


(lib.dinosaur_yellow_black = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// dinosaur
	this.instance = new lib.dinosauryellowblack();
	this.instance.setTransform(0,0,0.1995,0.1995);

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.dinosaur_yellow_black, new cjs.Rectangle(0,0,167.8,139.5), null);


(lib.dinosaur_red_black = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// dinosaur
	this.instance = new lib.dinosaurredblack();
	this.instance.setTransform(0,0,0.1995,0.1995);

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.dinosaur_red_black, new cjs.Rectangle(0,0,169,142.1), null);


(lib.dinosaur_green_black = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// dinosaur
	this.instance = new lib.dinosaurgreenblack();
	this.instance.setTransform(0,0,0.1996,0.1995);

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.dinosaur_green_black, new cjs.Rectangle(0,0,142.1,203.3), null);


(lib.dinosaur_blue_black = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// dinosaur
	this.instance = new lib.dinosaurblueblack();
	this.instance.setTransform(0,121.9,0.2002,0.2001,-49.7313);

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.dinosaur_blue_black, new cjs.Rectangle(0,0,225.4,225.4), null);


(lib.buttons = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// movie
	this.shape = new cjs.Shape();
	this.shape.graphics.beginFill("#FFFFFF").beginStroke().moveTo(-236.1,78).lineTo(-236.1,-78).lineTo(236.1,-78).lineTo(236.1,78).closePath();
	this.shape.setTransform(236.05,78.025);
	this.shape._off = true;

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(3).to({_off:false},0).wait(1));

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(0,0,472.1,156.1);


(lib.btn_start = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// Camada_1
	this.shape = new cjs.Shape();
	this.shape.graphics.beginFill("rgba(0,0,0,0.6)").beginStroke().moveTo(-56.6,56.5).curveTo(-80,33.1,-80,0).curveTo(-80,-33.2,-56.6,-56.6).curveTo(-33.2,-80,0,-80).curveTo(33.1,-80,56.5,-56.6).curveTo(80,-33.2,80,0).curveTo(80,33.1,56.5,56.5).curveTo(33.1,80,0,80).curveTo(-33.2,80,-56.6,56.5).closePath();
	this.shape.setTransform(80,80);

	this.shape_1 = new cjs.Shape();
	this.shape_1.graphics.beginFill("rgba(0,0,0,0.498)").beginStroke().moveTo(-56.6,56.5).curveTo(-80,33.1,-80,0).curveTo(-80,-33.2,-56.6,-56.6).curveTo(-33.2,-80,0,-80).curveTo(33.1,-80,56.5,-56.6).curveTo(80,-33.2,80,0).curveTo(80,33.1,56.5,56.5).curveTo(33.1,80,0,80).curveTo(-33.2,80,-56.6,56.5).closePath();
	this.shape_1.setTransform(80,80);

	this.shape_2 = new cjs.Shape();
	this.shape_2.graphics.beginFill("#FFFFFF").beginStroke().moveTo(-56.6,56.5).curveTo(-80,33.1,-80,0).curveTo(-80,-33.2,-56.6,-56.6).curveTo(-33.2,-80,0,-80).curveTo(33.1,-80,56.5,-56.6).curveTo(80,-33.2,80,0).curveTo(80,33.1,56.5,56.5).curveTo(33.1,80,0,80).curveTo(-33.2,80,-56.6,56.5).closePath();
	this.shape_2.setTransform(80,80);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape}]}).to({state:[{t:this.shape_1}]},1).to({state:[{t:this.shape_1}]},1).to({state:[{t:this.shape_2}]},1).wait(1));

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(0,0,160,160);


(lib.menu_start = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {"default":1,start:2,wait:3,check:4,error:5,success:6};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// timeline functions:
	this.frame_0 = function() {
		/**
		 *
		 *  Stop the movie clip.
		 */
		this.stop();
	}

	// actions tween:
	this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(7));

	// title
	this.instance = new lib.CachedBmp_30();
	this.instance.setTransform(29.6,67.05,0.5,0.5);

	this.instance_1 = new lib.CachedBmp_31();
	this.instance_1.setTransform(34.05,67.05,0.5,0.5);

	this.instance_2 = new lib.CachedBmp_32();
	this.instance_2.setTransform(18.9,67.05,0.5,0.5);

	this.instance_3 = new lib.CachedBmp_33();
	this.instance_3.setTransform(17.15,67.05,0.5,0.5);

	this.instance_4 = new lib.CachedBmp_34();
	this.instance_4.setTransform(32.55,67.05,0.5,0.5);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[]}).to({state:[{t:this.instance}]},1).to({state:[{t:this.instance_1}]},2).to({state:[{t:this.instance_2}]},1).to({state:[{t:this.instance_3}]},1).to({state:[{t:this.instance_4}]},1).wait(1));

	// menu
	this.shape = new cjs.Shape();
	this.shape.graphics.beginFill("#008000").beginStroke().moveTo(-56.6,56.6).curveTo(-80,33.2,-80,0).curveTo(-80,-33.2,-56.6,-56.6).curveTo(-33.2,-80,0,-80).curveTo(33.1,-80,56.5,-56.6).curveTo(80,-33.2,80,0).curveTo(80,33.2,56.5,56.6).curveTo(33.1,80,0,80).curveTo(-33.2,80,-56.6,56.6).closePath();
	this.shape.setTransform(80,80);

	this.shape_1 = new cjs.Shape();
	this.shape_1.graphics.beginFill("#FFFF00").beginStroke().moveTo(-56.6,56.5).curveTo(-80,33.1,-80,0).curveTo(-80,-33.2,-56.6,-56.6).curveTo(-33.2,-80,0,-80).curveTo(33.1,-80,56.5,-56.6).curveTo(80,-33.2,80,0).curveTo(80,33.1,56.5,56.5).curveTo(33.1,80,0,80).curveTo(-33.2,80,-56.6,56.5).closePath();
	this.shape_1.setTransform(80,80);

	this.shape_2 = new cjs.Shape();
	this.shape_2.graphics.beginFill("#0000FF").beginStroke().moveTo(-56.6,56.5).curveTo(-80,33.1,-80,0).curveTo(-80,-33.2,-56.6,-56.6).curveTo(-33.2,-80,0,-80).curveTo(33.1,-80,56.5,-56.6).curveTo(80,-33.2,80,0).curveTo(80,33.1,56.5,56.5).curveTo(33.1,80,0,80).curveTo(-33.2,80,-56.6,56.5).closePath();
	this.shape_2.setTransform(80,80);

	this.shape_3 = new cjs.Shape();
	this.shape_3.graphics.beginFill("#FF0000").beginStroke().moveTo(-56.6,56.5).curveTo(-80,33.1,-80,0).curveTo(-80,-33.2,-56.6,-56.6).curveTo(-33.2,-80,0,-80).curveTo(33.1,-80,56.5,-56.6).curveTo(80,-33.2,80,0).curveTo(80,33.1,56.5,56.5).curveTo(33.1,80,0,80).curveTo(-33.2,80,-56.6,56.5).closePath();
	this.shape_3.setTransform(80,80);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[]}).to({state:[{t:this.shape}]},1).to({state:[{t:this.shape_1}]},2).to({state:[{t:this.shape_2}]},1).to({state:[{t:this.shape_3}]},1).to({state:[{t:this.shape}]},1).wait(1));

	// size
	this.instance_5 = new lib.size_160x160();

	this.timeline.addTween(cjs.Tween.get(this.instance_5).wait(7));

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(0,0,160,160);


(lib.menu = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// text
	this.occ_text = new lib.text();
	this.occ_text.name = "occ_text";
	this.occ_text.setTransform(199.9,48,1,1,0,0,0,80.5,29.6);

	this.timeline.addTween(cjs.Tween.get(this.occ_text).wait(1));

	// menu
	this.shape = new cjs.Shape();
	this.shape.graphics.beginFill().beginStroke("#282E3A").setStrokeStyle(1,1,1).moveTo(-190.1,-60).lineTo(190,-60).curveTo(214.9,-60,232.4,-42.4).curveTo(250,-24.9,250,-0).lineTo(250,0).curveTo(250,24.9,232.4,42.4).curveTo(214.9,60,190,60).lineTo(-190.1,60).curveTo(-214.9,60,-232.5,42.4).curveTo(-250,24.9,-250,0).lineTo(-250,-0).curveTo(-250,-24.9,-232.5,-42.4).curveTo(-214.9,-60,-190.1,-60).closePath();
	this.shape.setTransform(199.8751,47.9575,0.8,0.8003);

	this.shape_1 = new cjs.Shape();
	this.shape_1.graphics.beginFill("#FFFFFF").beginStroke().moveTo(-190.1,60).curveTo(-214.9,60,-232.5,42.4).curveTo(-250,24.9,-250,0).lineTo(-250,-0).curveTo(-250,-24.9,-232.5,-42.4).curveTo(-214.9,-60,-190.1,-60).lineTo(190,-60).curveTo(214.9,-60,232.5,-42.4).curveTo(250,-24.9,250,-0).lineTo(250,0).curveTo(250,24.9,232.5,42.4).curveTo(214.9,60,190,60).closePath();
	this.shape_1.setTransform(199.8751,47.9575,0.8,0.8003);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_1},{t:this.shape}]}).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.menu, new cjs.Rectangle(-1.1,-1,402,98), null);


(lib.content_frame_04 = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	this.isSingleFrame = false;
	// timeline functions:
	this.frame_0 = function() {
		if(this.isSingleFrame) {
			return;
		}
		if(this.totalFrames == 1) {
			this.isSingleFrame = true;
		}
		/**
		 *
		 *  Stop the movie clip.
		 */
		this.stop();
		
		/**
		 *
		 * Class reference.
		 */
		const StageClass = window.StageClass;
		
		StageClass.genius.symbol.contentFrame04.frame01(this);
	}

	// actions tween:
	this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(1));

	// logo
	this.instance = new lib.big();
	this.instance.setTransform(208.3,676.35,0.3283,0.3283);

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

	// buttons
	this.btn_back = new lib.buttons();
	this.btn_back.name = "btn_back";
	this.btn_back.setTransform(710.1,290,1.0591,3.2041,0,0,0,0.1,0);
	new cjs.ButtonHelper(this.btn_back, 0, 1, 2, false, new lib.buttons(), 3);

	this.timeline.addTween(cjs.Tween.get(this.btn_back).wait(1));

	// menu
	this.instance_1 = new lib.CachedBmp_8();
	this.instance_1.setTransform(797.2,503.55,0.5,0.5);

	this.instance_2 = new lib.CachedBmp_7();
	this.instance_2.setTransform(709.5,479.5,0.5,0.5);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.instance_2},{t:this.instance_1}]}).wait(1));

	// status
	this.occ_name = new cjs.Text("", "28px 'Montserrat Medium'", "#FFFFFF");
	this.occ_name.name = "occ_name";
	this.occ_name.textAlign = "center";
	this.occ_name.lineHeight = 39;
	this.occ_name.lineWidth = 356;
	this.occ_name.parent = this;
	this.occ_name.setTransform(959.849,152.65,1.2499,1.2499);

	this.occ_high_score = new cjs.Text("0", "28px 'Montserrat Medium'", "#FFFFFF");
	this.occ_high_score.name = "occ_high_score";
	this.occ_high_score.textAlign = "center";
	this.occ_high_score.lineHeight = 39;
	this.occ_high_score.parent = this;
	this.occ_high_score.setTransform(959.8461,250.35,1.2499,1.2499);

	this.instance_3 = new lib.CachedBmp_29();
	this.instance_3.setTransform(889.6,209.35,0.5,0.5);

	this.instance_4 = new lib.CachedBmp_28();
	this.instance_4.setTransform(708.5,138.5,0.5,0.5);

	this.instance_5 = new lib.CachedBmp_27();
	this.instance_5.setTransform(0,0,0.5,0.5);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.instance_5},{t:this.instance_4},{t:this.instance_3},{t:this.occ_high_score},{t:this.occ_name}]}).wait(1));

	// background_mask (mask)
	var mask = new cjs.Shape();
	mask._off = true;
	mask.graphics.moveTo(-960,540).lineTo(-960,-540).lineTo(960,-540).lineTo(960,540).closePath();
	mask.setTransform(960,540);

	// background
	this.instance_6 = new lib.background();
	this.instance_6.setTransform(0,-661.9);

	var maskedShapeInstanceList = [this.instance_6];

	for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
		maskedShapeInstanceList[shapedInstanceItr].mask = mask;
	}

	this.timeline.addTween(cjs.Tween.get(this.instance_6).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.content_frame_04, new cjs.Rectangle(0,0,1920,1080), null);


(lib.content_frame_02 = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	this.isSingleFrame = false;
	// timeline functions:
	this.frame_0 = function() {
		if(this.isSingleFrame) {
			return;
		}
		if(this.totalFrames == 1) {
			this.isSingleFrame = true;
		}
		/**
		 *
		 *  Stop the movie clip.
		 */
		this.stop();
		
		/**
		 *
		 * Class reference.
		 */
		const StageClass = window.StageClass;
		
		StageClass.genius.symbol.contentFrame02.frame01(this);
	}

	// actions tween:
	this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(1));

	// logo
	this.instance = new lib.big();
	this.instance.setTransform(208.3,676.35,0.3283,0.3283);

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

	// buttons
	this.btn_start = new lib.buttons();
	this.btn_start.name = "btn_start";
	this.btn_start.setTransform(717.95,290,1.0591,3.2041,0,0,0,0.1,0);
	new cjs.ButtonHelper(this.btn_start, 0, 1, 2, false, new lib.buttons(), 3);

	this.timeline.addTween(cjs.Tween.get(this.btn_start).wait(1));

	// menu
	this.instance_1 = new lib.CachedBmp_2();
	this.instance_1.setTransform(867.85,503.55,0.5,0.5);

	this.instance_2 = new lib.CachedBmp_1();
	this.instance_2.setTransform(717.35,479.5,0.5,0.5);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.instance_2},{t:this.instance_1}]}).wait(1));

	// background_mask (mask)
	var mask = new cjs.Shape();
	mask._off = true;
	mask.graphics.moveTo(-960,540).lineTo(-960,-540).lineTo(960,-540).lineTo(960,540).closePath();
	mask.setTransform(960,540);

	// background
	this.instance_3 = new lib.background();
	this.instance_3.setTransform(0,-661.9);

	var maskedShapeInstanceList = [this.instance_3];

	for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
		maskedShapeInstanceList[shapedInstanceItr].mask = mask;
	}

	this.timeline.addTween(cjs.Tween.get(this.instance_3).wait(1));

	// size
	this.shape = new cjs.Shape();
	this.shape.graphics.beginFill("rgba(255,255,255,0)").beginStroke().moveTo(-960,540).lineTo(-960,-540).lineTo(960,-540).lineTo(960,540).closePath();
	this.shape.setTransform(960,540);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.content_frame_02, new cjs.Rectangle(0,0,1920,1080), null);


(lib.caret = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// caret
	this.instance = new lib.Interpolação1("synched",0);
	this.instance.setTransform(0,25);
	this.instance.alpha = 0;

	this.instance_1 = new lib.Interpolação2("synched",0);
	this.instance_1.setTransform(0,25);
	this.instance_1._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance).to({_off:true,alpha:1},14).wait(16));
	this.timeline.addTween(cjs.Tween.get(this.instance_1).to({_off:false},14).wait(1).to({startPosition:0},0).to({alpha:0},14).wait(1));

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(-1.5,-1.5,3,53);


(lib.button_yellow = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// button_yellow
	this.instance = new lib.dinosaur_yellow_black();
	this.instance.setTransform(-83.9,-69.7);
	this.instance.alpha = 0.6016;

	this.shape = new cjs.Shape();
	this.shape.graphics.beginFill("#FFFFFF").beginStroke().moveTo(-100,100).lineTo(-100,-100).lineTo(100,-100).lineTo(100,100).closePath();

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.instance,p:{alpha:0.6016}}]}).to({state:[{t:this.instance,p:{alpha:0.5}}]},1).to({state:[{t:this.instance,p:{alpha:0.5}}]},1).to({state:[{t:this.shape}]},1).wait(1));

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(-100,-100,200,200);


(lib.button_red = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// button_red
	this.instance = new lib.dinosaur_red_black();
	this.instance.setTransform(12.5,463.8,1,1,0,0,0,97,534.8);
	this.instance.alpha = 0.6016;

	this.shape = new cjs.Shape();
	this.shape.graphics.beginFill("#FFFFFF").beginStroke().moveTo(-100,100).lineTo(-100,-100).lineTo(100,-100).lineTo(100,100).closePath();

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.instance,p:{alpha:0.6016}}]}).to({state:[{t:this.instance,p:{alpha:0.5}}]},1).to({state:[{t:this.instance,p:{alpha:0.5}}]},1).to({state:[{t:this.shape}]},1).wait(1));

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(-100,-100,200,200);


(lib.button_green = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// button_green
	this.instance = new lib.dinosaur_green_black();
	this.instance.setTransform(-71.05,-101.65);
	this.instance.alpha = 0.6016;

	this.shape = new cjs.Shape();
	this.shape.graphics.beginFill("#FFFFFF").beginStroke().moveTo(-100,100).lineTo(-100,-100).lineTo(100,-100).lineTo(100,100).closePath();

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.instance,p:{alpha:0.6016}}]}).to({state:[{t:this.instance,p:{alpha:0.5}}]},1).to({state:[{t:this.instance,p:{alpha:0.5}}]},1).to({state:[{t:this.shape}]},1).wait(1));

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(-100,-101.6,200,203.3);


(lib.button_blue = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// button_blue
	this.instance = new lib.dinosaur_blue_black();
	this.instance.setTransform(-112.7,-112.7);
	this.instance.alpha = 0.6016;

	this.shape = new cjs.Shape();
	this.shape.graphics.beginFill("#FFFFFF").beginStroke().moveTo(-100,100).lineTo(-100,-100).lineTo(100,-100).lineTo(100,100).closePath();

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.instance,p:{alpha:0.6016}}]}).to({state:[{t:this.instance,p:{alpha:0.5}}]},1).to({state:[{t:this.instance,p:{alpha:0.5}}]},1).to({state:[{t:this.shape}]},1).wait(1));

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(-112.7,-112.7,225.4,225.4);


(lib.input_text_03 = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// caret
	this.occ_caret = new lib.caret();
	this.occ_caret.name = "occ_caret";
	this.occ_caret.setTransform(20,11.45);
	this.occ_caret.visible = false;

	this.timeline.addTween(cjs.Tween.get(this.occ_caret).wait(1));

	// error
	this.occ_error = new cjs.Text("", "18px 'Montserrat Medium'", "#FFFFFF");
	this.occ_error.name = "occ_error";
	this.occ_error.lineHeight = 25;
	this.occ_error.lineWidth = 676;
	this.occ_error.parent = this;
	this.occ_error.setTransform(22,74.95);

	this.timeline.addTween(cjs.Tween.get(this.occ_error).wait(1));

	// text_mask (mask)
	var mask = new cjs.Shape();
	mask._off = true;
	mask.graphics.moveTo(-340,36.5).lineTo(-340,-36.5).lineTo(340,-36.5).lineTo(340,36.5).closePath();
	mask.setTransform(360,36.475);

	// text
	this.occ_text = new cjs.Text("", "50px 'Montserrat Medium'", "#FFFFFF");
	this.occ_text.name = "occ_text";
	this.occ_text.lineHeight = 69;
	this.occ_text.lineWidth = 676;
	this.occ_text.parent = this;
	this.occ_text.setTransform(22,2);

	var maskedShapeInstanceList = [this.occ_text];

	for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
		maskedShapeInstanceList[shapedInstanceItr].mask = mask;
	}

	this.timeline.addTween(cjs.Tween.get(this.occ_text).wait(1));

	// placeholder
	this.occ_placeholder = new cjs.Text("E-Mail", "50px 'Montserrat Medium'", "#FFFFFF");
	this.occ_placeholder.name = "occ_placeholder";
	this.occ_placeholder.lineHeight = 69;
	this.occ_placeholder.alpha = 0.49803922;
	this.occ_placeholder.parent = this;
	this.occ_placeholder.setTransform(22,2);

	this.timeline.addTween(cjs.Tween.get(this.occ_placeholder).wait(1));

	// input
	this.shape = new cjs.Shape();
	this.shape.graphics.beginFill().beginStroke("#74ACBC").setStrokeStyle(3,1,1).moveTo(-323.6,-36.5).lineTo(323.5,-36.5).curveTo(338.6,-36.5,349.3,-25.8).curveTo(360,-15.1,360,-0).lineTo(360,0).curveTo(360,15.1,349.3,25.8).curveTo(338.6,36.5,323.5,36.5).lineTo(-323.6,36.5).curveTo(-338.7,36.5,-349.4,25.8).curveTo(-360,15.1,-360,0).lineTo(-360,-0).curveTo(-360,-15.1,-349.4,-25.8).curveTo(-338.7,-36.5,-323.6,-36.5).closePath();
	this.shape.setTransform(360,36.475);

	this.shape_1 = new cjs.Shape();
	this.shape_1.graphics.beginFill("rgba(51,51,51,0.498)").beginStroke().moveTo(-323.6,36.5).curveTo(-338.6,36.5,-349.3,25.8).curveTo(-360,15.1,-360,0).lineTo(-360,-0).curveTo(-360,-15.1,-349.3,-25.8).curveTo(-338.6,-36.5,-323.6,-36.5).lineTo(323.5,-36.5).curveTo(338.7,-36.5,349.3,-25.8).curveTo(360,-15.1,360,-0).lineTo(360,0).curveTo(360,15.1,349.3,25.8).curveTo(338.7,36.5,323.5,36.5).closePath();
	this.shape_1.setTransform(360,36.475);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_1},{t:this.shape}]}).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.input_text_03, new cjs.Rectangle(-1.5,-1.5,723,103.3), null);


(lib.input_text_02 = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// caret
	this.occ_caret = new lib.caret();
	this.occ_caret.name = "occ_caret";
	this.occ_caret.setTransform(20,11.45);
	this.occ_caret.visible = false;

	this.timeline.addTween(cjs.Tween.get(this.occ_caret).wait(1));

	// error
	this.occ_error = new cjs.Text("", "18px 'Montserrat Medium'", "#FFFFFF");
	this.occ_error.name = "occ_error";
	this.occ_error.lineHeight = 25;
	this.occ_error.lineWidth = 676;
	this.occ_error.parent = this;
	this.occ_error.setTransform(22,74.95);

	this.timeline.addTween(cjs.Tween.get(this.occ_error).wait(1));

	// text_mask (mask)
	var mask = new cjs.Shape();
	mask._off = true;
	mask.graphics.moveTo(-340,36.5).lineTo(-340,-36.5).lineTo(340,-36.5).lineTo(340,36.5).closePath();
	mask.setTransform(360,36.475);

	// text
	this.occ_text = new cjs.Text("", "50px 'Montserrat Medium'", "#FFFFFF");
	this.occ_text.name = "occ_text";
	this.occ_text.lineHeight = 69;
	this.occ_text.lineWidth = 676;
	this.occ_text.parent = this;
	this.occ_text.setTransform(22,2);

	var maskedShapeInstanceList = [this.occ_text];

	for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
		maskedShapeInstanceList[shapedInstanceItr].mask = mask;
	}

	this.timeline.addTween(cjs.Tween.get(this.occ_text).wait(1));

	// placeholder
	this.occ_placeholder = new cjs.Text("Nome da Empresa", "50px 'Montserrat Medium'", "#FFFFFF");
	this.occ_placeholder.name = "occ_placeholder";
	this.occ_placeholder.lineHeight = 69;
	this.occ_placeholder.lineWidth = 473;
	this.occ_placeholder.alpha = 0.49803922;
	this.occ_placeholder.parent = this;
	this.occ_placeholder.setTransform(22,2);

	this.timeline.addTween(cjs.Tween.get(this.occ_placeholder).wait(1));

	// input
	this.shape = new cjs.Shape();
	this.shape.graphics.beginFill().beginStroke("#74ACBC").setStrokeStyle(3,1,1).moveTo(-323.6,-36.5).lineTo(323.5,-36.5).curveTo(338.6,-36.5,349.3,-25.8).curveTo(360,-15.1,360,-0).lineTo(360,0).curveTo(360,15.1,349.3,25.8).curveTo(338.6,36.5,323.5,36.5).lineTo(-323.6,36.5).curveTo(-338.7,36.5,-349.4,25.8).curveTo(-360,15.1,-360,0).lineTo(-360,-0).curveTo(-360,-15.1,-349.4,-25.8).curveTo(-338.7,-36.5,-323.6,-36.5).closePath();
	this.shape.setTransform(360,36.475);

	this.shape_1 = new cjs.Shape();
	this.shape_1.graphics.beginFill("rgba(51,51,51,0.498)").beginStroke().moveTo(-323.6,36.5).curveTo(-338.6,36.5,-349.3,25.8).curveTo(-360,15.1,-360,0).lineTo(-360,-0).curveTo(-360,-15.1,-349.3,-25.8).curveTo(-338.6,-36.5,-323.6,-36.5).lineTo(323.5,-36.5).curveTo(338.7,-36.5,349.3,-25.8).curveTo(360,-15.1,360,-0).lineTo(360,0).curveTo(360,15.1,349.3,25.8).curveTo(338.7,36.5,323.5,36.5).closePath();
	this.shape_1.setTransform(360,36.475);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_1},{t:this.shape}]}).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.input_text_02, new cjs.Rectangle(-1.5,-1.5,723,103.3), null);


(lib.input_text_01 = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// caret
	this.occ_caret = new lib.caret();
	this.occ_caret.name = "occ_caret";
	this.occ_caret.setTransform(20,11.45);
	this.occ_caret.visible = false;

	this.timeline.addTween(cjs.Tween.get(this.occ_caret).wait(1));

	// error
	this.occ_error = new cjs.Text("", "18px 'Montserrat Medium'", "#FFFFFF");
	this.occ_error.name = "occ_error";
	this.occ_error.lineHeight = 25;
	this.occ_error.lineWidth = 676;
	this.occ_error.parent = this;
	this.occ_error.setTransform(22,74.95);

	this.timeline.addTween(cjs.Tween.get(this.occ_error).wait(1));

	// text_mask (mask)
	var mask = new cjs.Shape();
	mask._off = true;
	mask.graphics.moveTo(-340,36.5).lineTo(-340,-36.5).lineTo(340,-36.5).lineTo(340,36.5).closePath();
	mask.setTransform(360,36.475);

	// text
	this.occ_text = new cjs.Text("", "50px 'Montserrat Medium'", "#FFFFFF");
	this.occ_text.name = "occ_text";
	this.occ_text.lineHeight = 69;
	this.occ_text.lineWidth = 676;
	this.occ_text.parent = this;
	this.occ_text.setTransform(22,2);

	var maskedShapeInstanceList = [this.occ_text];

	for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
		maskedShapeInstanceList[shapedInstanceItr].mask = mask;
	}

	this.timeline.addTween(cjs.Tween.get(this.occ_text).wait(1));

	// placeholder
	this.occ_placeholder = new cjs.Text("Nome Completo", "50px 'Montserrat Medium'", "#FFFFFF");
	this.occ_placeholder.name = "occ_placeholder";
	this.occ_placeholder.lineHeight = 69;
	this.occ_placeholder.lineWidth = 421;
	this.occ_placeholder.alpha = 0.49803922;
	this.occ_placeholder.parent = this;
	this.occ_placeholder.setTransform(22,2);

	this.timeline.addTween(cjs.Tween.get(this.occ_placeholder).wait(1));

	// input
	this.shape = new cjs.Shape();
	this.shape.graphics.beginFill().beginStroke("#74ACBC").setStrokeStyle(3,1,1).moveTo(-323.6,-36.5).lineTo(323.5,-36.5).curveTo(338.6,-36.5,349.3,-25.8).curveTo(360,-15.1,360,-0).lineTo(360,0).curveTo(360,15.1,349.3,25.8).curveTo(338.6,36.5,323.5,36.5).lineTo(-323.6,36.5).curveTo(-338.7,36.5,-349.4,25.8).curveTo(-360,15.1,-360,0).lineTo(-360,-0).curveTo(-360,-15.1,-349.4,-25.8).curveTo(-338.7,-36.5,-323.6,-36.5).closePath();
	this.shape.setTransform(360,36.475);

	this.shape_1 = new cjs.Shape();
	this.shape_1.graphics.beginFill("rgba(51,51,51,0.498)").beginStroke().moveTo(-323.6,36.5).curveTo(-338.6,36.5,-349.3,25.8).curveTo(-360,15.1,-360,0).lineTo(-360,-0).curveTo(-360,-15.1,-349.3,-25.8).curveTo(-338.6,-36.5,-323.6,-36.5).lineTo(323.5,-36.5).curveTo(338.7,-36.5,349.3,-25.8).curveTo(360,-15.1,360,-0).lineTo(360,0).curveTo(360,15.1,349.3,25.8).curveTo(338.7,36.5,323.5,36.5).closePath();
	this.shape_1.setTransform(360,36.475);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_1},{t:this.shape}]}).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.input_text_01, new cjs.Rectangle(-1.5,-1.5,723,103.3), null);


(lib.form = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// input_text
	this.input_text_email = new lib.input_text_03();
	this.input_text_email.name = "input_text_email";
	this.input_text_email.setTransform(600,323.75);

	this.input_text_business = new lib.input_text_02();
	this.input_text_business.name = "input_text_business";
	this.input_text_business.setTransform(600,213.55);

	this.input_text_name = new lib.input_text_01();
	this.input_text_name.name = "input_text_name";
	this.input_text_name.setTransform(600,103.35);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.input_text_name},{t:this.input_text_business},{t:this.input_text_email}]}).wait(1));

	// size
	this.shape = new cjs.Shape();
	this.shape.graphics.beginFill("rgba(255,255,255,0)").beginStroke().moveTo(-960,540).lineTo(-960,-540).lineTo(960,-540).lineTo(960,540).closePath();
	this.shape.setTransform(960,540);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.form, new cjs.Rectangle(0,0,1920,1080), null);


(lib.content_frame_03 = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	this.isSingleFrame = false;
	// timeline functions:
	this.frame_0 = function() {
		if(this.isSingleFrame) {
			return;
		}
		if(this.totalFrames == 1) {
			this.isSingleFrame = true;
		}
		/**
		 *
		 *  Stop the movie clip.
		 */
		this.stop();
		
		/**
		 *
		 * Class reference.
		 */
		const StageClass = window.StageClass;
		
		StageClass.genius.symbol.contentFrame03.frame01(this);
	}

	// actions tween:
	this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(1));

	// logo
	this.instance = new lib.big();
	this.instance.setTransform(60,868.2,0.1707,0.1707);

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

	// gram_mask (mask)
	var mask = new cjs.Shape();
	mask._off = true;
	mask.graphics.moveTo(-960,540).lineTo(-960,-540).lineTo(960,-540).lineTo(960,540).closePath();
	mask.setTransform(960,540);

	// gram
	this.instance_1 = new lib.gram01();
	this.instance_1.setTransform(0,-23.4);

	this.instance_2 = new lib.gram02();
	this.instance_2.setTransform(0,-661.9);

	var maskedShapeInstanceList = [this.instance_1,this.instance_2];

	for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
		maskedShapeInstanceList[shapedInstanceItr].mask = mask;
	}

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.instance_2},{t:this.instance_1}]}).wait(1));

	// buttons
	this.btn_genius_yellow = new lib.button_yellow();
	this.btn_genius_yellow.name = "btn_genius_yellow";
	this.btn_genius_yellow.setTransform(700.1,294.8,1.8921,1.8921,0,0,0,0.1,0.1);
	new cjs.ButtonHelper(this.btn_genius_yellow, 0, 1, 2, false, new lib.button_yellow(), 3);

	this.btn_genius_red = new lib.button_red();
	this.btn_genius_red.name = "btn_genius_red";
	this.btn_genius_red.setTransform(2114.75,5819.25,1.8921,1.8921,0,0,0,486.1,2680.6);
	new cjs.ButtonHelper(this.btn_genius_red, 0, 1, 2, false, new lib.button_red(), 3);

	this.btn_genius_blue = new lib.button_blue();
	this.btn_genius_blue.name = "btn_genius_blue";
	this.btn_genius_blue.setTransform(1210.4,328.15,1.8921,1.8921,0,0,0,0.1,0.1);
	new cjs.ButtonHelper(this.btn_genius_blue, 0, 1, 2, false, new lib.button_blue(), 3);

	this.btn_start = new lib.btn_start();
	this.btn_start.name = "btn_start";
	this.btn_start.setTransform(880,460);
	new cjs.ButtonHelper(this.btn_start, 0, 1, 2, false, new lib.btn_start(), 3);

	this.btn_genius_green = new lib.button_green();
	this.btn_genius_green.name = "btn_genius_green";
	this.btn_genius_green.setTransform(703.8,732.15,1.8921,1.8921,0,0,0,0,0.1);
	new cjs.ButtonHelper(this.btn_genius_green, 0, 1, 2, false, new lib.button_green(), 3);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.btn_genius_green},{t:this.btn_start},{t:this.btn_genius_blue},{t:this.btn_genius_red},{t:this.btn_genius_yellow}]}).wait(1));

	// menu
	this.instance_3 = new lib.dinosaurblue1();
	this.instance_3.setTransform(997.1,345.5,0.3787,0.3786,-49.732);

	this.instance_4 = new lib.dinosauryellow();
	this.instance_4.setTransform(541.35,162.85,0.3775,0.3775);

	this.instance_5 = new lib.dinosaurred();
	this.instance_5.setTransform(1035.35,613.05,0.3775,0.3775);

	this.instance_6 = new lib.dinosaurgreen();
	this.instance_6.setTransform(569.5,539.8,0.3775,0.3775);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.instance_6},{t:this.instance_5},{t:this.instance_4},{t:this.instance_3}]}).wait(1));

	// menu_start
	this.occ_menu_start = new lib.menu_start();
	this.occ_menu_start.name = "occ_menu_start";
	this.occ_menu_start.setTransform(880,460);

	this.timeline.addTween(cjs.Tween.get(this.occ_menu_start).wait(1));

	// status
	this.occ_count_down = new cjs.Text("0", "28px 'Montserrat Medium'", "#FFFFFF");
	this.occ_count_down.name = "occ_count_down";
	this.occ_count_down.textAlign = "center";
	this.occ_count_down.lineHeight = 39;
	this.occ_count_down.parent = this;
	this.occ_count_down.setTransform(374.8451,248.4,1.4999,1.4999);

	this.instance_7 = new lib.CachedBmp_26();
	this.instance_7.setTransform(326.55,176.3,0.5,0.5);

	this.instance_8 = new lib.CachedBmp_25();
	this.instance_8.setTransform(223.5,138.5,0.5,0.5);

	this.instance_9 = new lib.CachedBmp_24();
	this.instance_9.setTransform(1381.05,176.3,0.5,0.5);

	this.occ_score = new cjs.Text("0", "28px 'Montserrat Medium'", "#FFFFFF");
	this.occ_score.name = "occ_score";
	this.occ_score.textAlign = "center";
	this.occ_score.lineHeight = 39;
	this.occ_score.parent = this;
	this.occ_score.setTransform(1509.7451,248.35,1.4999,1.4999);

	this.instance_10 = new lib.CachedBmp_25();
	this.instance_10.setTransform(1358.5,138.5,0.5,0.5);

	this.occ_high_score = new cjs.Text("0", "28px 'Montserrat Medium'", "#FFFFFF");
	this.occ_high_score.name = "occ_high_score";
	this.occ_high_score.textAlign = "center";
	this.occ_high_score.lineHeight = 39;
	this.occ_high_score.parent = this;
	this.occ_high_score.setTransform(1509.7451,504.9,1.4999,1.4999);

	this.instance_11 = new lib.CachedBmp_22();
	this.instance_11.setTransform(1379.55,432.8,0.5,0.5);

	this.instance_12 = new lib.CachedBmp_25();
	this.instance_12.setTransform(1358.5,395.05,0.5,0.5);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.instance_12},{t:this.instance_11},{t:this.occ_high_score},{t:this.instance_10},{t:this.occ_score},{t:this.instance_9},{t:this.instance_8},{t:this.instance_7},{t:this.occ_count_down}]}).wait(1));

	// background_mask (mask)
	var mask_1 = new cjs.Shape();
	mask_1._off = true;
	mask_1.graphics.moveTo(-960,540).lineTo(-960,-540).lineTo(960,-540).lineTo(960,540).closePath();
	mask_1.setTransform(960,540);

	// background
	this.instance_13 = new lib.background();
	this.instance_13.setTransform(0,-661.9);

	var maskedShapeInstanceList = [this.instance_13];

	for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
		maskedShapeInstanceList[shapedInstanceItr].mask = mask_1;
	}

	this.timeline.addTween(cjs.Tween.get(this.instance_13).wait(1));

	// size
	this.shape = new cjs.Shape();
	this.shape.graphics.beginFill("rgba(255,255,255,0)").beginStroke().moveTo(-960,540).lineTo(-960,-540).lineTo(960,-540).lineTo(960,540).closePath();
	this.shape.setTransform(960,540);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.content_frame_03, new cjs.Rectangle(0,0,1920,1080), null);


(lib.content_frame_01 = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	this.isSingleFrame = false;
	// timeline functions:
	this.frame_0 = function() {
		if(this.isSingleFrame) {
			return;
		}
		if(this.totalFrames == 1) {
			this.isSingleFrame = true;
		}
		/**
		 *
		 *  Stop the movie clip.
		 */
		this.stop();
		
		/**
		 *
		 * Class reference.
		 */
		const StageClass = window.StageClass;
		
		StageClass.genius.symbol.contentFrame01.frame01(this);
	}

	// actions tween:
	this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(1));

	// form
	this.occ_form = new lib.form();
	this.occ_form.name = "occ_form";

	this.timeline.addTween(cjs.Tween.get(this.occ_form).wait(1));

	// logo
	this.instance = new lib.big();
	this.instance.setTransform(60,868.2,0.1707,0.1707);

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

	// buttons
	this.btn_submit = new lib.buttons();
	this.btn_submit.name = "btn_submit";
	this.btn_submit.setTransform(760.15,892.05,0.8473,0.6152,0,0,0,0.2,0.1);
	new cjs.ButtonHelper(this.btn_submit, 0, 1, 2, false, new lib.buttons(), 3);

	this.timeline.addTween(cjs.Tween.get(this.btn_submit).wait(1));

	// menu
	this.occ_button = new lib.menu();
	this.occ_button.name = "occ_button";
	this.occ_button.setTransform(760,892);

	this.timeline.addTween(cjs.Tween.get(this.occ_button).wait(1));

	// background_mask (mask)
	var mask = new cjs.Shape();
	mask._off = true;
	mask.graphics.moveTo(-960,540).lineTo(-960,-540).lineTo(960,-540).lineTo(960,540).closePath();
	mask.setTransform(960,540);

	// background
	this.instance_1 = new lib.background();
	this.instance_1.setTransform(0,-661.9);

	var maskedShapeInstanceList = [this.instance_1];

	for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
		maskedShapeInstanceList[shapedInstanceItr].mask = mask;
	}

	this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(1));

	// size
	this.shape = new cjs.Shape();
	this.shape.graphics.beginFill("rgba(255,255,255,0)").beginStroke().moveTo(-960,540).lineTo(-960,-540).lineTo(960,-540).lineTo(960,540).closePath();
	this.shape.setTransform(960,540);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.content_frame_01, new cjs.Rectangle(0,0,1920,1080), null);


// stage content:
(lib.index = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {frame01:1,frame02:2,frame03:3,frame04:4};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	this.actionFrames = [0,1];
	// timeline functions:
	this.frame_0 = function() {
		/**
		 *
		 *  Stop the movie clip.
		 */
		this.stop();
		
		/**
		 *
		 * Class reference.
		 */
		const StageClass = window.StageClass;
		
		StageClass.genius.scene.frame01(this);
	}
	this.frame_1 = function() {
		/**
		 *
		 *  Stop the movie clip.
		 */
		this.stop();
	}

	// actions tween:
	this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(1).call(this.frame_1).wait(4));

	// ContentFrame
	this.occ_frame_01 = new lib.content_frame_01();
	this.occ_frame_01.name = "occ_frame_01";

	this.occ_frame_02 = new lib.content_frame_02();
	this.occ_frame_02.name = "occ_frame_02";
	this.occ_frame_02.setTransform(960,540,1,1,0,0,0,960,540);

	this.occ_frame_03 = new lib.content_frame_03();
	this.occ_frame_03.name = "occ_frame_03";

	this.occ_frame_04 = new lib.content_frame_04();
	this.occ_frame_04.name = "occ_frame_04";

	this.timeline.addTween(cjs.Tween.get({}).to({state:[]}).to({state:[{t:this.occ_frame_01}]},1).to({state:[{t:this.occ_frame_02}]},1).to({state:[{t:this.occ_frame_03}]},1).to({state:[{t:this.occ_frame_04}]},1).wait(1));

	this._renderFirstFrame();

}).prototype = p = new lib.AnMovieClip();
p.nominalBounds = new cjs.Rectangle(0,-121.9,1920,1468);
// library properties:
lib.properties = {
	id: '4EA7803F9C31B3498A0023731D21335A',
	width: 1920,
	height: 1080,
	fps: 30,
	color: "#282E3A",
	opacity: 1.00,
	manifest: [
		{src:"./images/CachedBmp_27.png", id:"CachedBmp_27"},
		{src:"./images/index_atlas_1.png", id:"index_atlas_1"},
		{src:"./images/index_atlas_2.png", id:"index_atlas_2"},
		{src:"./images/index_atlas_3.png", id:"index_atlas_3"},
		{src:"./images/index_atlas_4.png", id:"index_atlas_4"},
		{src:"./images/index_atlas_5.png", id:"index_atlas_5"},
		{src:"./images/index_atlas_6.png", id:"index_atlas_6"}
	],
	preloads: []
};



// bootstrap callback support:

(lib.Stage = function(canvas) {
	createjs.Stage.call(this, canvas);
}).prototype = p = new createjs.Stage();

p.setAutoPlay = function(autoPlay) {
	this.tickEnabled = autoPlay;
}
p.play = function() { this.tickEnabled = true; this.getChildAt(0).gotoAndPlay(this.getTimelinePosition()) }
p.stop = function(ms) { if(ms) this.seek(ms); this.tickEnabled = false; }
p.seek = function(ms) { this.tickEnabled = true; this.getChildAt(0).gotoAndStop(lib.properties.fps * ms / 1000); }
p.getDuration = function() { return this.getChildAt(0).totalFrames / lib.properties.fps * 1000; }

p.getTimelinePosition = function() { return this.getChildAt(0).currentFrame / lib.properties.fps * 1000; }

an.bootcompsLoaded = an.bootcompsLoaded || [];
if(!an.bootstrapListeners) {
	an.bootstrapListeners=[];
}

an.bootstrapCallback=function(fnCallback) {
	an.bootstrapListeners.push(fnCallback);
	if(an.bootcompsLoaded.length > 0) {
		for(var i=0; i<an.bootcompsLoaded.length; ++i) {
			fnCallback(an.bootcompsLoaded[i]);
		}
	}
};

an.compositions = an.compositions || {};
an.compositions['4EA7803F9C31B3498A0023731D21335A'] = {
	getStage: function() { return exportRoot.stage; },
	getLibrary: function() { return lib; },
	getSpriteSheet: function() { return ss; },
	getImages: function() { return img; }
};

an.compositionLoaded = function(id) {
	an.bootcompsLoaded.push(id);
	for(var j=0; j<an.bootstrapListeners.length; j++) {
		an.bootstrapListeners[j](id);
	}
}

an.getComposition = function(id) {
	return an.compositions[id];
}


an.makeResponsive = function(isResp, respDim, isScale, scaleType, domContainers) {		
	var lastW, lastH, lastS=1;		
	window.addEventListener('resize', resizeCanvas);		
	resizeCanvas();		
	function resizeCanvas() {			
		var w = lib.properties.width, h = lib.properties.height;			
		var iw = window.innerWidth, ih=window.innerHeight;			
		var pRatio = window.devicePixelRatio || 1, xRatio=iw/w, yRatio=ih/h, sRatio=1;			
		if(isResp) {                
			if((respDim=='width'&&lastW==iw) || (respDim=='height'&&lastH==ih)) {                    
				sRatio = lastS;                
			}				
			else if(!isScale) {					
				if(iw<w || ih<h)						
					sRatio = Math.min(xRatio, yRatio);				
			}				
			else if(scaleType==1) {					
				sRatio = Math.min(xRatio, yRatio);				
			}				
			else if(scaleType==2) {					
				sRatio = Math.max(xRatio, yRatio);				
			}			
		}
		domContainers[0].width = w * pRatio * sRatio;			
		domContainers[0].height = h * pRatio * sRatio;
		domContainers.forEach(function(container) {				
			container.style.width = w * sRatio + 'px';				
			container.style.height = h * sRatio + 'px';			
		});
		stage.scaleX = pRatio*sRatio;			
		stage.scaleY = pRatio*sRatio;
		lastW = iw; lastH = ih; lastS = sRatio;            
		stage.tickOnUpdate = false;            
		stage.update();            
		stage.tickOnUpdate = true;		
	}
}
an.handleSoundStreamOnTick = function(event) {
	if(!event.paused){
		var stageChild = stage.getChildAt(0);
		if(!stageChild.paused || stageChild.ignorePause){
			stageChild.syncStreamSounds();
		}
	}
}
an.handleFilterCache = function(event) {
	if(!event.paused){
		var target = event.target;
		if(target){
			if(target.filterCacheList){
				for(var index = 0; index < target.filterCacheList.length ; index++){
					var cacheInst = target.filterCacheList[index];
					if((cacheInst.startFrame <= target.currentFrame) && (target.currentFrame <= cacheInst.endFrame)){
						cacheInst.instance.cache(cacheInst.x, cacheInst.y, cacheInst.w, cacheInst.h);
					}
				}
			}
		}
	}
}


})(createjs = createjs||{}, AdobeAn = AdobeAn||{});
